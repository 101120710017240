import {
    AfterViewInit,
    Component,
    inject,
    Input,
    OnInit,
    TemplateRef,
    Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {HandleQuery} from "../../common/handle-query";
import {ChartOptions, ChartOptionType} from "../dashboard/dashboard.types";
import {combineLatest, map, mergeMap, Observable, of, ReplaySubject, Subject, take} from "rxjs";
import lodash, {cloneDeep} from "lodash";
import {DashboardContext} from "../dashboard/dashboard.context";
import {AppContext, CompletenessInfo} from "../../app-context";
import {
    AggregatedDataPoint,
    Connection,
    ContractMeasurementResult,
    DataType,
    DayOfWeekMeasurementsResult,
    GetContractMeasurements,
    GetDayOfWeekMeasurements,
    GetLocationMeasurements,
    GetPortfolioMeasurements,
    Location,
    LocationMeasurementsResult,
    MeasurementsResult,
    Organisation,
    PortfolioMeasurementsResult,
    TimeRange,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {openModal, sendQuery} from "../../common/app-common-utils";
import moment from "moment";
import {
    asSourceIds,
    entityToSourceInfo,
    SourceInfo,
    SourceSelectionChangeEvent
} from "../../utils/source-providers/sources-provider";
import {EntityType} from "../../handlers/entity";
import {HandleEvent} from "../../common/handle-event";
import {ChartDataPerMeasurement, DashboardTime, TimeChangedEvent} from "../../utils/chart-data-provider";
import {ChartModalOptions, ChartUtilsService} from "../charts/chart-utils.service";
import {HandleCommand} from "../../common/handle-command";
import {AnimationTypes} from "../../common/modal/modal";
import {MeterViewEntity} from "../../handlers/meter-views-standalone-handler";

@Component({
    selector: 'app-measurements-handler',
    templateUrl: './measurements-handler.component.html',
    styleUrls: ['./measurements-handler.component.scss']
})
@Handler()
export class MeasurementsHandlerComponent extends View implements OnInit, AfterViewInit {
    chartUtils: ChartUtilsService = inject(ChartUtilsService);

    @Input() data: MeasurementsHandlerComponentData;
    @Input() locationDashboard: boolean;
    @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;
    dashboardHistory: DashboardTime[] = [];

    filtersSubject: Subject<DataQueryFilters>;

    ngOnInit() {
        if (lodash.has(this.data, "isLocationDashboard")) {
            this.locationDashboard = this.data.isLocationDashboard;
        }
        this.filtersSubject = new ReplaySubject(1);
    }

    ngAfterViewInit() {
        this.setData = this.data;
    }

    set setData(data: MeasurementsHandlerComponentData) {
        this.data = data;
        if (data.component) {
            this.renderSubComponent(data.component);
        }
        this.publishFiltersChanged({
            timeRange: this.dashboardTimeToQuery(this.data.timeRange),
            sources: this.data.selectedSources,
            allSelectedSources: this.data.allSelectedSources
        })
    }

    private publishFiltersChanged(filters: DataQueryFilters) {
        this.filtersSubject.next(filters);
        this.publishEvent("filtersChanged", filters);
    }

    @HandleEvent("sourceSelectionChange")
    sourceSelectionChange(selectedSources: SourceSelectionChangeEvent, event: CustomEvent) {
        this.data.selectedSources = selectedSources.selectedItems;
        this.data.allSelectedSources = selectedSources.allSelectedItems;
        this.filtersSubject.pipe(take(1)).subscribe(f => this.publishFiltersChanged((<DataQueryFilters>{
            ...f,
            sources: this.data.selectedSources,
            allSelectedSources: this.data.allSelectedSources
        })));
        if (this.data.chartModalData?.fullScreen) {
            event.stopPropagation();
            return false;
        }
    }

    @HandleEvent("timeChanged")
    timeChanged(payload: TimeChangedEvent, event: CustomEvent) {
        if (payload.historyType === "add") {
            this.dashboardHistory.push(this.data.timeRange);
        }
        if (payload.historyType === "remove") {
            this.dashboardHistory.pop();
        }
        this.data.timeRange = payload.timeRange;
        this.filtersSubject.pipe(take(1)).subscribe(f => this.publishFiltersChanged((<DataQueryFilters>{
            ...f,
            timeRange: this.dashboardTimeToQuery(this.data.timeRange)
        })));
        if (this.data.chartModalData?.fullScreen) {
            event.stopPropagation();
            return false;
        }
    }

    @HandleQuery("getDashboardHistory")
    getDashboardHistory(): Observable<DashboardTime[]> {
        return of(this.dashboardHistory);
    }

    @HandleQuery("isPortfolio")
    isPortfolio(): Observable<boolean> {
        return of(!this.locationDashboard);
    }

    @HandleQuery("getChartOptions")
    getChartOptions(type: ChartOptionType): Observable<ChartOptions> {
        if (lodash.isEmpty(type)) {
            console.warn("Dashboard chart option type is required to get chart options");
            return of(null);
        }
        const chartOption = (this.data.chartOptions || {})[type];
        return of(chartOption || DashboardContext.defaultDashboard().info.chartOptions[type]);
    }

    @HandleQuery("getChartDataQueryFilters")
    getChartDataQueryFilters(): Subject<DataQueryFilters> {
        return this.filtersSubject;
    }

    @HandleQuery("getTimeRange")
    getTimeRange(): Observable<DashboardTime> {
        return this.subscribeTo("getChartDataQueryFilters").pipe(map((f: DataQueryFilters) => f.timeRange));
    }

    @HandleQuery("getContractMeasurements")
    getContractMeasurements(): Observable<ContractMeasurementResult> {
        return this.subscribeTo("getChartDataQueryFilters").pipe(mergeMap((filters: DataQueryFilters) => {
            return filters.timeRange && filters.sources ? sendQuery("com.flowmaps.api.measurements.GetContractMeasurements", <GetContractMeasurements>{
                timeRange: filters.timeRange,
                sourceIds: asSourceIds(this.cleanupSelection(this.data.selectedSources)),
                resolution: filters.timeRange.resolution,
                unrounded: ![TimeResolution.year, TimeResolution.month].includes(filters.timeRange.resolution)
            }) : of(<ContractMeasurementResult>{
                co2measurements: {
                    measurements: {},
                    estimatedMeasurements: {}
                },
                costsMeasurements: []
            });
        }));
    }

    @HandleQuery("getDayOfWeekMeasurements")
    getDayOfWeekMeasurements(): Observable<DayOfWeekMeasurementsResult> {
        return this.subscribeTo("getChartDataQueryFilters").pipe(mergeMap((filters: DataQueryFilters) =>
            filters.timeRange && filters.sources && moment(filters.timeRange.end).diff(moment(filters.timeRange.start), "day") < 367
                ? sendQuery("com.flowmaps.api.measurements.GetDayOfWeekMeasurements", <GetDayOfWeekMeasurements>{
                    timeRange: filters.timeRange,
                    sources: asSourceIds(this.cleanupSelection(this.data.selectedSources))
                }) : of({measurements: {}})));
    }

    @HandleQuery("getCompleteness")
    getCompleteness(timeRange: TimeRange): Observable<CompletenessInfo[]> {
        return this.getChartDataQueryFilters().pipe(mergeMap(result => {
            if (result.sources) {
                const emptyConnections = result.allSelectedSources.filter(c => c.type === EntityType.connection)
                    .map(s => s.source.connection as Connection)
                    .filter(s => !s.meters.length);
                return this.sendQuery("getPrimaryMeterViews", asSourceIds(result.sources))
                    .pipe(map((m: MeterViewEntity[]) => AppContext.computeCompletenessPerTimeRange(m, timeRange, emptyConnections)));
            }
            return of([]);
        }))
    }

    @HandleQuery("getMeasurementsData")
    getMeasurementsData(range: DashboardTime): Observable<ChartDataPerMeasurement> {
        const timeRange = lodash.isEmpty(range) ? this.data.timeRange : range;
        return combineLatest([sendQuery("getOrganisations"), this.getChartDataQueryFilters()])
            .pipe(mergeMap(result => {
                if (this.locationDashboard) {
                    return this.getLocationMeasurements({...result[1], timeRange: timeRange})
                        .pipe(map(data => !lodash.isEmpty(data)
                            ? {totals: data.byMeter, totalsPerYear: data.totalsPerYear} : {}));
                }
                return this.getPortfolioMeasurements({...result[1], timeRange: timeRange})
                    .pipe(map(data => !lodash.isEmpty(data)
                        ? {
                            totals: [data.totals],
                            byLocation: this.mergeAliasesWithLocation(lodash.cloneDeep(data.byLocation), result[0])
                        } : {}))
            }));
    }

    @HandleQuery("getPortfolioMeasurements")
    getPortfolioMeasurements(queryFilters?: DataQueryFilters): Observable<PortfolioMeasurementsResult> {
        return (lodash.isEmpty(queryFilters) ? this.subscribeTo("getChartDataQueryFilters") : of(queryFilters)).pipe(mergeMap((filters: DataQueryFilters) =>
            filters.timeRange && filters.sources
                ? sendQuery("com.flowmaps.api.measurements.GetPortfolioMeasurements", <GetPortfolioMeasurements>{
                    timeRange: {start: filters.timeRange.start, end: filters.timeRange.end},
                    sources: asSourceIds(this.cleanupSelection(this.data.selectedSources)),
                    resolution: filters.timeRange.resolution,
                    unrounded: ![TimeResolution.year, TimeResolution.month].includes(filters.timeRange.resolution)
                })
                : of({})));
    }

    @HandleQuery("getLocationMeasurements")
    getLocationMeasurements(queryFilters?: DataQueryFilters): Observable<LocationMeasurementsResult> {
        return (lodash.isEmpty(queryFilters) ? this.subscribeTo("getChartDataQueryFilters") : of(queryFilters)).pipe(mergeMap((filters: DataQueryFilters) =>
            filters.timeRange && filters.sources?.length
                ? sendQuery("com.flowmaps.api.measurements.GetLocationMeasurements", <GetLocationMeasurements>{
                    timeRange: {start: filters.timeRange.start, end: filters.timeRange.end},
                    locationIds: asSourceIds(this.data.allSelectedSources).locationIds,
                    resolution: filters.timeRange.resolution,
                    unrounded: ![TimeResolution.year, TimeResolution.month].includes(filters.timeRange.resolution)
                }) : of({})));
    }

    @HandleCommand("openChartInModal")
    openChartInModal(command: MeasurementsHandlerOpenModalCommand): void {
        this.sendQuery("getChartDataQueryFilters").subscribe((f: DataQueryFilters) => {
            const modalOptions = cloneDeep(command.chartModalData);
            modalOptions.fullScreen = true;
            const data = <MeasurementsHandlerComponentData>{
                chartOptions: command.chartOptions,
                component: command.component,
                chartModalData: modalOptions,
                timeRange: f.timeRange,
                selectedSources: f.sources,
                allSelectedSources: f.allSelectedSources,
                isLocationDashboard: this.locationDashboard
            };
            openModal(MeasurementsHandlerComponent, data, command.container, {
                cssClass: "modal-dialog-centered modal-xl d-flex align-items-stretch",
                style: "height: 90vh; min-height: 90vh; width: 90vw; min-width: 90vw;",
                animation: AnimationTypes.zoomIn
            });
        });
    }

    @HandleQuery("groupByEntityId")
    groupByEntityId(): Observable<boolean> {
        return of(this.locationDashboard);
    }

    private cleanupSelection = (selection: SourceInfo[]): SourceInfo[] => {
        const selectedIds = selection.map(s => s.id);
        let toBeDeleted: string[] = [];
        for (const sourceInfo of selection) {
            const parent = sourceInfo.source.getParentAsEntity();
            if (parent) {
                if (selectedIds.includes(parent.getEntityId())) {
                    toBeDeleted.push(sourceInfo.id);
                }
                if (parent.getChildIds().length > 1 && parent.getChildIds().every(c => selectedIds.includes(c))) {
                    toBeDeleted = toBeDeleted.concat(parent.getChildIds());
                    selection.push(entityToSourceInfo(parent));
                }
            }
        }
        if (toBeDeleted.length) {
            const newSelection = lodash.uniqBy(selection.filter(s => !toBeDeleted.includes(s.id)), s => s.id);
            return this.cleanupSelection(newSelection);
        }
        return selection;
    }

    private renderSubComponent(component: Type<unknown>) {
        const comp = component;
        if (comp instanceof TemplateRef) {
            this.container.createEmbeddedView(comp);
        } else {
            const componentRef = this.container.createComponent(comp);
            const component = componentRef.instance;
            component['data'] = this.data.chartModalData;
        }
    }

    private dashboardTimeToQuery = (time: DashboardTime): DashboardTime => {
        if (!time) {
            return null;
        }
        const timeRange = AppContext.timeRangeToQuery(time);
        return {
            start: timeRange.start,
            end: timeRange.end,
            label: time.label,
            resolution: time.resolution
        };
    }

    private mergeAliasesWithLocation(data: MeasurementsResult[], organisations: Organisation[]): MeasurementsResult[] {
        return data.map(r => {
            const location = this.getLocation(organisations, r.entityId);
            if (!location || location.locationId !== r.entityId) {
                return null;
            }
            if (!location.aliasIds?.length) {
                return r;
            }
            const aliasResults = data.filter(a => location.aliasIds.includes(a.entityId));
            aliasResults.forEach(v => r = this.mergeMeasurementsResult(r, v))
            return r;
        }).filter(r => r);
    }

    private mergeMeasurementsResult = (destination: MeasurementsResult, other: MeasurementsResult): MeasurementsResult => {
        destination.measurements = this.mergeMeasurements(destination.measurements, other.measurements);
        destination.estimatedMeasurements = this.mergeMeasurements(destination.estimatedMeasurements, other.estimatedMeasurements);
        return destination;
    }

    private mergeMeasurements(destination: { [P in DataType]?: AggregatedDataPoint[] }, other: { [P in DataType]?: AggregatedDataPoint[] }): { [P in DataType]?: AggregatedDataPoint[] } {
        const findByTimeRange = (data: AggregatedDataPoint[], a: AggregatedDataPoint): AggregatedDataPoint => data.find(
            d => d.timeRange.start === a.timeRange.start && d.timeRange.end === a.timeRange.end);
        Object.entries(other).forEach(e => {
            const destinationValue: AggregatedDataPoint[] = destination[e[0]];
            if (!destinationValue) {
                destination[e[0]] = e[1];
                return;
            }
            e[1].forEach(d => {
                const destinationPoint = findByTimeRange(destinationValue, d);
                if (!destinationPoint) {
                    destinationValue.push(d);
                    return;
                }
                const aggregationMethod = AppContext.getAggregationMethod(e[0] as DataType);
                destinationPoint.value = aggregationMethod([destinationPoint.value, d.value]);
            });
            destination[e[0]] = destinationValue;
        });
        return destination;
    }

    private getLocation = (organisations: Organisation[], id: string): Location => organisations.flatMap(
        o => o.locations.filter(l => l.locationId === id || l.aliasIds?.includes(id)))[0];
}

export interface DataQueryFilters {
    timeRange: DashboardTime;
    sources: SourceInfo[];
    allSelectedSources: SourceInfo[];
}

export interface MeasurementsHandlerOpenModalCommand {
    chartOptions?: { [P in ChartOptionType]?: ChartOptions };
    component?;
    chartModalData?: ChartModalOptions;
    container: ViewContainerRef;
    modalClasses?: string;
}

export interface MeasurementsHandlerComponentData {
    chartOptions?: { [P in ChartOptionType]?: ChartOptions };
    timeRange?: DashboardTime;
    selectedSources?: SourceInfo[];
    allSelectedSources?: SourceInfo[];
    component?;
    chartModalData?: ChartModalOptions;
    isLocationDashboard?: boolean;
}