import {Component, ElementRef, inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {AppContext} from "../../app-context";
import {Observable, of} from "rxjs";
import {
    asSourceIds,
    entityToSourceInfo,
    SourceSelectionChangeEvent
} from "../../utils/source-providers/sources-provider";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardContext} from "../dashboard/dashboard.context";
import {DeleteDashboard, SaveDashboard} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject, localTimeFormat, lodash, removeIf, uuid} from "../../common/utils";
import {RefdataUtils} from "../refdata/refdata-utils";
import {LocationProvider} from "../../utils/source-providers/location-provider";
import {Dashboard, DashboardType} from "../dashboard/dashboard.types";
import {cloneDeep} from "lodash";
import {Entity} from "../../handlers/entity";
import {AppCommonUtils, publishEvent, sendQuery} from "../../common/app-common-utils";
import {Handler} from "../../common/handler";
import {View} from "../../common/view";
import {
    DataQueryFilters,
    MeasurementsHandlerComponentData
} from "../measurements-component/measurements-handler.component";
import {HandleQuery} from "../../common/handle-query";
import {MomentDateFieldRange} from "../../common/date/date-range/date-field-range";
import {HandleEvent} from "../../common/handle-event";


@Component({
    selector: 'app-location-dashboard',
    templateUrl: './location-dashboard.component.html',
    styleUrls: ['./location-dashboard.component.scss'],
    host: {
        class: 'w-100'
    }
})
@Handler()
export class LocationDashboardComponent extends View implements OnInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;

    locationIds: string[];
    location: Entity;
    locations: Entity[];
    sourcesProvider: LocationProvider;
    dashboard: Dashboard = {info: {}};
    measurementsHandlerData: MeasurementsHandlerComponentData = {};
    private initialised: boolean;

    private filters: DataQueryFilters;
    private ngZone: NgZone = inject(NgZone);
    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);

    searchLocation = (term: string): Observable<Entity[]> => this.sendQuery("searchLocations", term);

    @ViewChild("sourceField", { read: ElementRef }) sourceField: ElementRef;

    constructor() {
        super();
        this.sourcesProvider = new LocationProvider();
        this.route.params.subscribe(params => {
            this.route.queryParams.subscribe(queryParams => {
                const param = params['dashboard'];
                this.dashboard = param ? DashboardContext.base64ToDashboard(param) : LocationDashboardComponent.defaultDashboard();
                const savedLabel = this.dashboard.info?.predefinedTimeRange;
                if (savedLabel) {
                    const matchingRange = DashboardContext.defaultRanges.find(range => range.label === savedLabel);
                    if (matchingRange) {
                        this.dashboard.info.timeRange.start = matchingRange.start.format(localTimeFormat);
                        this.dashboard.info.timeRange.end = matchingRange.end.format(localTimeFormat);
                    }
                }
                this.sourcesProvider.selectedSources = this.dashboard.info.sources;
                this.locationIds = this.dashboard.info.sources.locationIds;
                this.measurementsHandlerData.chartOptions = this.dashboard.info.chartOptions;
                this.measurementsHandlerData.timeRange = {
                    resolution: this.dashboard.info.resolution,
                    label: savedLabel,
                    ...this.dashboard.info.timeRange
                };
                if (this.initialised) {
                    this.getLocation();
                }
            });
        });
    }

    ngOnInit() {
        this.initialised = true;
        this.getLocation();
    }

    private getLocation() {
        sendQuery("getLocationsAsEntities").subscribe((locations: Entity[]) => {
            this.locations = locations;
            if (this.dashboard.info.sources.locationIds) {
                this.location = locations.find(l => this.locationIds.includes(l.location.locationId));
                if (this.location) {
                    setTimeout(() => this.updateLocation(this.location), 0);
                }
            }
        });
    }

    updateLocation(entity: Entity) {
        this.locationIds = [entity.location.locationId, ...entity.location.aliasIds];
        this.location = entity;
        this.sourcesProvider.setData([entity.location]);
        publishEvent("sourceSelectionChange", <SourceSelectionChangeEvent> {
            selectedItems: [entityToSourceInfo(entity)],
            allSelectedItems: this.sourcesProvider.getFlatSourceSelection()
        }, this.sourceField);
    }

    saveDashboard = (): void => {
        const savedDashboard: Dashboard = cloneObject(this.dashboard);
        savedDashboard.dashboardId = uuid();
        const command: SaveDashboard = {
            info: savedDashboard.info,
            dashboardId: savedDashboard.dashboardId,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.SaveDashboard", command, () =>
            AppCommonUtils.navigateToUrl("/location/dashboard/" + encodeURIComponent(DashboardContext.dashboardToBase64(savedDashboard))));
    }

    deleteDashboard = (id): void => {
        const command: DeleteDashboard = {
            dashboardId: id,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.DeleteDashboard", command, () => {
            removeIf(AppContext.userProfile.dashboards, d => d.dashboardId === id);
            if (this.dashboard?.dashboardId === id) {
                AppCommonUtils.navigateToUrl("/location/dashboard");
            }
        });
    }

    updateAndNavigateToDashboard() {
        this.ngZone.run(() => {
            const dashboard = cloneDeep(this.dashboard);
            if (this.filters && this.filters.timeRange && this.filters.sources) {
                this.dashboard.info.sources = asSourceIds(this.filters.sources);
                this.dashboard.info.timeRange = { start: this.filters.timeRange.start, end: this.filters.timeRange.end };
                this.dashboard.info.resolution = this.filters.timeRange.resolution;
                this.dashboard.info.predefinedTimeRange = this.filters.timeRange.label;
                return lodash.isEqual(dashboard, this.dashboard) ? Promise.resolve()
                    : this.router.navigateByUrl(`/location/dashboard/${DashboardContext.dashboardToBase64(this.dashboard)}`);
            }
        });
    }

    static defaultDashboard = (): Dashboard => {
        const dashboard = DashboardContext.defaultDashboard();
        dashboard.info.type = DashboardType.Location;
        dashboard.info.chartOptions["Electricity"].splitOffPeak = false;
        dashboard.info.chartOptions["Electricity"].groupByEntityId = true;
        dashboard.info.chartOptions["Electricity"].showPower = true;
        dashboard.info.chartOptions["Electricity"].showGrossProduction = false;
        dashboard.info.chartOptions["Water"].groupByEntityId = true;
        return dashboard;
    }

    @HandleEvent("filtersChanged")
    onFiltersChanged(filters: DataQueryFilters, ev: CustomEvent) {
        this.filters = filters;
        this.updateAndNavigateToDashboard();
        ev.stopPropagation();
        return false;
    }

    @HandleQuery("getTimeRanges")
    getTimeRanges(): Observable<MomentDateFieldRange[]> {
        return of(DashboardContext.defaultRanges);
    }
}