import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import {Worker} from 'html2pdf.js';
import moment, {Moment} from "moment";
import {
    ConnectionType,
    DataType,
    DeleteReport,
    GetUser,
    Report,
    ReportScheduleResolution,
    ReportTimeRange,
    UserProfile
} from "@flowmaps/flowmaps-typescriptmodels";
import {Dashboard, DashboardInfo} from "../../dashboard/dashboard.types";
import {SourceInfo, SourcesProvider} from "../../../utils/source-providers/sources-provider";
import {ReportComponentData, ReportDetailsComponent} from "../report-details/report-details.component";
import {AppContext, CompletenessInfo} from "../../../app-context";
import {uuid} from "../../../common/utils";
import {cloneDeep} from "lodash";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import {DashboardContext} from '../../dashboard/dashboard.context';
import {LocationProvider} from "../../../utils/source-providers/location-provider";
import {OrganisationProvider} from "../../../utils/source-providers/organisation-provider";
import {DateFieldRange, MomentDateFieldRange} from "../../../common/date/date-range/date-field-range";
import {DateRangeUtils} from "../../../common/date/date-range/date-range.utils";
import {ReportUtils} from "../report-utils";
import {getConnectionsExcel} from "../report-runner/report-runner.component";
import {AppCommonUtils, publishEvent} from "../../../common/app-common-utils";
import {EntityType} from "../../../handlers/entity";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {downloadConnectionsAsExcel} from "../../refdata/connections/connections-overview/connections-list-excel";
import {downloadBulkMonthData} from "../../refdata/connections/connections-overview/bulk-data-download";
import {
    DataQueryFilters,
    MeasurementsHandlerComponent,
    MeasurementsHandlerComponentData
} from "../../measurements-component/measurements-handler.component";
import {DashboardTime, TimeChangedEvent} from "../../../utils/chart-data-provider";
import {HandleQuery} from "../../../common/handle-query";
import {mergeMap, Observable, of} from "rxjs";
import {HandleEvent} from "../../../common/handle-event";

@Component({
    selector: 'app-report-html-to-pdf',
    templateUrl: './report-html-to-pdf.component.html',
    styleUrls: ['./report-html-to-pdf.component.scss'],
})
@Handler()
export class ReportHtmlToPdfComponent extends View implements AfterViewInit {
    appContext = AppContext;
    reportUtils = ReportUtils;
    _report: Report;
    owner: UserProfile;
    sourceProvider: SourcesProvider<any>;
    timeRange: DashboardTime = DashboardContext.defaultRange();
    measurementsHandlerData: MeasurementsHandlerComponentData = {};

    @ViewChild("pdfContent") pdfContent: ElementRef;
    @ViewChild("header", {read: ElementRef}) header: ElementRef;
    @ViewChild("measurementsHandler") measurementsHandler: MeasurementsHandlerComponent;
    @Output() allChartsRendered: EventEmitter<any> = new EventEmitter<any>();
    searchSourceProvider: OrganisationProvider = new OrganisationProvider();

    electricityConsumption: DataType = DataType.electricityConsumption;
    electricityFeedIn: DataType = DataType.electricityFeedIn;
    gasConsumption: DataType = DataType.gasConsumption;
    waterConsumption: DataType = DataType.waterConsumption;
    dashboard: Dashboard = {info: {}};
    completenessData: CompletenessInfo[] = [];

    @ViewChild("dateRangeField", { read: ElementRef }) dateRangeField: ElementRef;

    ngAfterViewInit() {
        this.calculateResolution();
        publishEvent("timeChanged", <TimeChangedEvent>{
            timeRange: this.timeRange
        }, this.dateRangeField);
        this.measurementsHandler.subscribeTo("getChartDataQueryFilters")
            .pipe(mergeMap((filters: DataQueryFilters) => this.measurementsHandler.sendQuery("getCompleteness", filters.timeRange)))
            .subscribe(c => {
                this.completenessData = c;
                setTimeout(() => this.allChartsRendered.emit(), 5000);
            });
    }

    @Input()
    set report(report: Report) {
        this._report = report;
        if (report) {
            this.calculateTimeRange();
            if (report.owner === AppContext.userProfile.userId) {
                this.owner = AppContext.userProfile;
                this.renderReport();
            } else if (AppContext.isAdmin() && report.owner) {
                this.sendQuery("com.flowmaps.api.user.GetUser", <GetUser>{userId: report.owner}).subscribe(u => {
                    this.owner = u;
                    this.renderReport();
                })
            } else {
                this.renderReport();
            }
        }
    }

    hasSourceForConnectionType = (connectionType: ConnectionType | string) => this.sourceProvider
        .hasSourceForConnectionType(connectionType as ConnectionType);

    get rangeResolution(): string {
        switch (this._report.info.query.range.resolution) {
            case ReportScheduleResolution.year:
                return "Annual";
            case ReportScheduleResolution.month:
                return "Monthly";
            case ReportScheduleResolution.week:
                return "Weekly";
            case ReportScheduleResolution.day:
                return "Daily";
        }
    }

    calculateTimeRange = () => {
        const range = this._report.info.query.range;
        const start = moment().subtract(range.value, range.resolution).startOf(range.resolution);
        this.timeRange = {
            start: start.toISOString(),
            end: start.clone().add(1, range.resolution).toISOString()
        };
        this.timeRange.label = DateRangeUtils.getRangeLabel({
            start: moment(this.timeRange.start),
            end: moment(this.timeRange.end)
        }, ReportUtils.defaultRanges);
    }

    renderReport() {
        const info: DashboardInfo = DashboardContext.defaultDashboard().info;
        info.timeRange = this.timeRange;
        info.resolution = this._report.info.query.resolution;
        this.searchSourceProvider.selectedSources = this._report.info.query.sources;
        this.measurementsHandlerData.chartOptions = info.chartOptions;
        this.updateMeterOptions(info);
        this.setCorrectDataProvider(info);
    }

    private updateMeterOptions(info: DashboardInfo) {
        this.setMeterOptions(ConnectionType.Electricity, info);
        this.setMeterOptions(ConnectionType.Gas, info);
        this.setMeterOptions(ConnectionType.Heat, info);
        this.setMeterOptions(ConnectionType.Water, info);
    }

    private setMeterOptions(connectionType: ConnectionType, info: DashboardInfo) {
        info.chartOptions[connectionType].showAsTable = true;
        info.chartOptions[connectionType].splitOffPeak = false;
        info.chartOptions[connectionType].compare = {
            relative: true,
            comparedYear: 1,
            enabled: true
        }
    }

    sourceSelectionUpdated(sources: SourceInfo[]) {
        this.dashboard.info.sources = this._report.info.query.sources = this.searchSourceProvider.selectedSources;
        this.sourceProvider.selectedSources = this.searchSourceProvider.selectedSources;
        this.updateMeterOptions(this.dashboard.info);
        this.setCorrectDataProvider(this.dashboard.info);
    }

    setCorrectDataProvider(info: DashboardInfo) {
        this.sourceProvider = this.showByMeter() ? new LocationProvider() : new OrganisationProvider();
        this.sourceProvider.selectedSources = this._report.info.query.sources;
        this.sourceProvider.collectData();
    }

    private showByMeter() {
        return this.searchSourceProvider.getSelectedSourcesByType(EntityType.location).length === 1;
    }

    timeRangeChanged = (value: DateFieldRange) => {
        this.timeRange = value;
        if (value) {
            this.calculateResolution();
        }
        publishEvent("timeChanged", <TimeChangedEvent> {
            timeRange: {
                resolution: this._report.info.query.resolution,
                ...this.timeRange
            }
        }, this.dateRangeField);
    }

    calculateResolution = () => {
        this._report.info.query.range = this.calculateReportRange(this.timeRange);
        this.timeRange.resolution = AppContext.resolutionForTimeRange(this.timeRange);
        this._report.info.query.resolution = this.timeRange.resolution;
    }

    calculateReportRange = (range: DateFieldRange): ReportTimeRange => {
        if (!range.label) {
            return {};
        }
        const start = moment(range.start);
        const end = moment(range.end);
        const reportRangeNow = this.getReportTimeRange(start, moment());
        const reportRangeEnd = this.getReportTimeRange(start, end);
        return reportRangeNow.resolution === reportRangeEnd.resolution
            ? reportRangeNow : {
                resolution: reportRangeEnd.resolution,
                value: Math.max(0, reportRangeEnd.value - 1)
            };
    }

    getReportTimeRange = (start: Moment, end: Moment): ReportTimeRange => {
        const duration = moment.duration(end.diff(start));
        const resolution = this.getResolutionOfDuration(duration);
        return {
            resolution: resolution,
            value: duration.get(resolution)
        }
    }

    private getResolutionOfDuration(duration: moment.Duration) {
        return Math.round(duration.asYears()) > 0 ? ReportScheduleResolution.year
            : Math.round(duration.asMonths()) > 0 ? ReportScheduleResolution.month
                : Math.round(duration.asWeeks()) > 0 ? ReportScheduleResolution.week
                    : ReportScheduleResolution.day;
    }

    completenessTitle = (connectionTypes: ConnectionType[] | string[]): string =>
        AppContext.completenessTitle(this.completenessData, this.sourceProvider
            .getAllSourcesByType(EntityType.connection), connectionTypes);

    completeness = (connectionTypes: ConnectionType[] | string[]): number =>
        AppContext.completeness(this.completenessData, this.sourceProvider
            .getAllSourcesByType(EntityType.connection), connectionTypes);

    completenessColor = (connectionTypes: ConnectionType[] | string[]): string =>
        AppContext.completenessColor(this.completenessData, this.sourceProvider
            .getAllSourcesByType(EntityType.connection), connectionTypes);


    saveReport = () => {
        const isNewReport = !this._report.reportId;
        const info = cloneDeep(this._report.info);
        if (isNewReport) {
            info.name = null;
        }
        this.openModal(ReportDetailsComponent, <ReportComponentData>{
            reportId: isNewReport ? uuid() : this._report.reportId,
            info: info
        });
    };

    deleteReport = () => {
        this.sendCommand("com.flowmaps.api.reporting.DeleteReport", <DeleteReport>{
            reportId: this._report.reportId
        }, () => {
            AppCommonUtils.navigateToUrl("/report/default");
        });
    }

    async createPdf(): Worker<jsPDF> {
        let opt = {
            margin: [1, 0, 0.3, 0],
            filename: 'report.pdf',
            image: {type: 'jpeg', quality: 0.98},
            html2canvas: {scale: 2, logging: true, dpi: 192, letterRendering: true},
            pagebreak: {before: '.page-break', mode: ['avoid-all', 'css', 'legacy']},
            jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
        };

        let headerImage = await html2canvas(this.header.nativeElement).then(canvas => {
            return canvas;
        });

        return html2pdf().set(opt).from(this.pdfContent.nativeElement).toPdf().get('pdf')
            .then((pdf: jsPDF) => {
                let totalPages: number = pdf.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.addImage(headerImage, 'jpeg', 0, 0, pdf.internal.pageSize.getWidth(), 1);
                }

                return pdf;
            });
    }

    async download() {
        const pdf = await this.createPdf();
        pdf.save('report.pdf');
        getConnectionsExcel(this.elementRef, this.sourceProvider, downloadConnectionsAsExcel).subscribe();
        downloadBulkMonthData(this.sourceProvider, this.timeRange);
    }

    @HandleQuery("getTimeRanges")
    getTimeRanges(): Observable<MomentDateFieldRange[]> {
        return of(ReportUtils.defaultRanges);
    }

    @HandleEvent("filtersChanged")
    onFiltersChanged(filters: DataQueryFilters, ev: CustomEvent) {
        ev.stopPropagation();
        return false;
    }
}

