import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    inject,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {RefdataUtils} from "../../refdata/refdata-utils";
import {map, Observable} from "rxjs";
import {
    BuildingType,
    BuildingTypeInfo,
    DataType,
    Location,
    MeasurementsResult,
    Organisation,
    PortfolioMeasurementsResult
} from "@flowmaps/flowmaps-typescriptmodels";
import {ChartDatasetExtended, MeasurementDataset} from "../base-measurement-chart";
import {ChartDataProvider} from "../../../utils/chart-data-provider";
import {ChartData, ChartTypeRegistry, Plugin, ScaleOptionsByType} from "chart.js";
import lodash, {cloneDeep} from "lodash";
import {ChartOptions} from "../../dashboard/dashboard.types";
import {EnergyCompassUtils} from "./energy-compass.utils";
import {EnergyCompassLabelPlugin} from "./energy-compass-label.plugin";
import {CustomBackgroundColorPlugin} from "../scatter-chart/plugins/custom-background-color.plugin";
import {ChartAreaBorderPlugin} from "../scatter-chart/plugins/chart-area-border.plugin";
import {EnergyCompassParisProofLinePlugin} from "./energy-compass-paris-proof-line.plugin";
import {ChartModalOptions, ChartUtilsService} from "../chart-utils.service";
import {TooltipModel} from "chart.js/dist/types";
import {sendCommandAndForget, sendQuery} from "../../../common/app-common-utils";
import {View} from "../../../common/view";
import {MeasurementsHandlerOpenModalCommand} from "../../measurements-component/measurements-handler.component";

@Component({
    selector: 'app-energy-compass-chart',
    templateUrl: './energy-compass-chart.component.html',
    styleUrls: ['./energy-compass-chart.component.scss'],
    providers: [{provide: EnergyCompassChartComponent, useExisting: forwardRef(() => EnergyCompassChartComponent)}]
})
export class EnergyCompassChartComponent extends View implements OnInit {
    private chartUtils: ChartUtilsService = inject(ChartUtilsService);
    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

    @Input() showInReport: boolean;
    tableElement: ElementRef;

    @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;

    energyCompassLabelPlugin = new EnergyCompassLabelPlugin();

    weiiLabels: string[] = ["WENG", "Paris proof", "Very economical", "Economical", "Average", "Uneconomical", "Very uneconomical"];
    plugins: Plugin<'scatter'>[] = [new ChartAreaBorderPlugin(), new CustomBackgroundColorPlugin(),
        new EnergyCompassParisProofLinePlugin(), this.energyCompassLabelPlugin];
    gridLineColor: string = "rgba(110,110,110,0.8)";
    suggestedMinY: number = -100;
    pluginOptions = {
        chartAreaBorder: {
            borderColor: this.gridLineColor,
            borderWidth: 1,
            borderDash: 0,
            borderDashOffset: 0
        },
        customBackgroundColor: {
            color: "rgba(241,218,149,0.3)"
        }
    }

    modalOptions: ChartModalOptions = {
        fullScreen: false,
    }

    chartDataProvider = new ChartDataProvider();
    scatterChartData: ChartData;

    options: ChartOptions = {
        compare: {
            enabled: false
        },
        showAsTable: false
    }

    buildingTypes: BuildingType[] = [];
    plottedBuildingTypes: PlottedBuildingType[] = [];
    dotRgba: number = 0.7;
    buildingTypeColors = {
        RESTAURANT: `rgb(67,150,63,${this.dotRgba})`,
        OFFICE: `rgb(190,82,143,${this.dotRgba})`
    }

    numberOfUnknownLocations: number = 0;
    energyCompassSettingsLocalStorageKey = "energy-compass-options";
    defaultSettings: EnergyCompassSettings = {
        showUnknown: false,
        shownBuildingTypes: null
    }
    settings: EnergyCompassSettings = JSON.parse(localStorage.getItem(this.energyCompassSettingsLocalStorageKey)) || this.defaultSettings;
    measurementTypes: DataType[] = [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak,
        DataType.gasConsumption, DataType.heatConsumption];

    @Input()
    set data(d: ChartModalOptions) {
        this.modalOptions = d;
        this.energyCompassLabelPlugin.fullScreen = d?.fullScreen;
    }

    ngOnInit(): void {
        this.subscribeTo("getPortfolioMeasurements").subscribe((d: PortfolioMeasurementsResult) => this.updateChart(d));
    }

    private uniqueId = lodash.uniqueId();
    getInputId = (id: string) => this.uniqueId + id + (this.modalOptions.fullScreen ? '-fullscreen' : '');

    saveSettings() {
        localStorage.setItem(this.energyCompassSettingsLocalStorageKey, JSON.stringify(this.settings));
    }

    get scales() {
        const scales: {
            [key: string]: ScaleOptionsByType<ChartTypeRegistry['scatter']['scales']>;
        } = {};
        this.buildingTypes.forEach(b => {
            const scaleTicks = this.getScaleTicks(b);
            const shownBuildingTypes = this.settings.shownBuildingTypes;
            scales[b.info.code] = (<ScaleOptionsByType<ChartTypeRegistry['scatter']['scales']>>{
                type: "linear",
                display: Object.values(shownBuildingTypes).length === 1 && !!shownBuildingTypes[b.buildingTypeId],
                title: {
                    display: true,
                    text: 'kWh/m²'
                },
                position: "right",
                min: 0,
                suggestedMin: 0,
                max: scaleTicks.length - 1,
                suggestedMax: scaleTicks.length - 1,
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    count: scaleTicks.length,
                    stepSize: 1,
                    callback: (tickValue, index, ticks) => index === scaleTicks.length - 1
                        ? `> ${this.chartUtils.transformDecimal(scaleTicks[index])}` : this.chartUtils.transformDecimal(scaleTicks[index])
                },
                beginAtZero: false
            });
            scales[b.info.code]["realTicks"] = scaleTicks;
            return scales[b.info.code];
        });
        return scales;
    }

    private getScaleTicks = (b: BuildingType) => Object.values(b.info.efficiencyScale)
        .concat([this.suggestedMinY, b.info.efficiencyScale["Uneconomical"] * 2])
        .sort((a, b) => a < b ? -1 : 1);

    private updateChart(d: PortfolioMeasurementsResult) {
        sendQuery("findBuildingTypes").subscribe(b => {
            this.buildingTypes = b;
            this.getLocations(d).subscribe(l => {
                const groupedLocations: lodash.Dictionary<LocationWithMeasurements[]> = lodash.groupBy(l, (l) => l.location.info.buildingType);
                this.plottedBuildingTypes = cloneDeep(this.buildingTypes);
                if (!this.settings.shownBuildingTypes) {
                    this.settings.shownBuildingTypes = {};
                    this.plottedBuildingTypes.forEach(p => this.settings.shownBuildingTypes[p.buildingTypeId] = p.info);
                }
                this.plottedBuildingTypes.forEach(p => p.numberOfLocations = groupedLocations[p.buildingTypeId]?.length || 0);
                if (this.settings.showUnknown) {
                    this.numberOfUnknownLocations = l.filter(l => !l.location.info?.energyLabel && !l.location.refdata?.energyLabel).length;
                }
                this.scatterChartData = {
                    datasets: Object.entries(groupedLocations)
                        .map(e => ({
                            buildingType: this.plottedBuildingTypes.find(b => b.buildingTypeId === e[0]),
                            values: e[1]
                        }))
                        .filter(e => e.buildingType)
                        .map(e => this.datasetForLocations(e.buildingType, e.values))
                };
                this.emitData();
                this.changeDetectorRef.detectChanges();
            })
        })
    }

    private emitData() {
        this.chartDataProvider.emit({
            datasets: this.scatterChartData.datasets.map(d => <MeasurementDataset>{
                dataset: d
            })
        });
    }

    getLocations = (d: PortfolioMeasurementsResult): Observable<LocationWithMeasurements[]> => {
        const locationEntityIds: string[] = d.byLocation?.map(l => l.entityId) || [];
        return sendQuery("getOrganisations")
            .pipe(map((o: Organisation[]) => o.flatMap(o => o.locations)))
            .pipe(map(locs => locs.filter(l => locationEntityIds.includes(l.locationId) || l.aliasIds.some(a => locationEntityIds.includes(a)))))
            .pipe(map(locs => locs.map(l => (<LocationWithMeasurements>{
                location: l,
                measurements: d.byLocation.filter(byLoc => byLoc.entityId === l.locationId || l.aliasIds.includes(byLoc.entityId))
            }))));
    }

    toggleShowAsTable() {
        this.options.showAsTable = !this.options.showAsTable;
        setTimeout(() => this.chartDataProvider.emit({
            datasets: this.scatterChartData.datasets.map(d => <MeasurementDataset>{
                dataset: d
            })
        }), 0);
    }

    openModal() {
        this.sendCommandAndForget("openChartInModal", <MeasurementsHandlerOpenModalCommand>{
            component: EnergyCompassChartComponent,
            chartOptions: this.options,
            chartModalData: this.modalOptions,
            container: this.container,
            modalClasses: "modal-dialog-centered modal-xl d-flex align-items-stretch"
        });
    }

    closeModal = () => sendCommandAndForget("closeModal");

    private datasetForLocations(buildingType: BuildingType, locations: LocationWithMeasurements[]) {
        const color = this.buildingTypeColors[buildingType.info.code] || this.stringToRgba(buildingType.info.code);
        const locationWithMeasurements = locations.filter(l => this.settings.showUnknown || l.location.info.energyLabel || l.location.refdata?.energyLabel)
            .filter(l => this.hasConsumptionData(l) && RefdataUtils.getLocationArea(l.location));
        return <ChartDatasetExtended>{
            pointRadius: 4,
            backgroundColor: color,
            pointBorderColor: color,
            pointBorderWidth: 0,
            yAxisID: buildingType.info.code,
            indexAxis: "y",
            hidden: !this.settings.shownBuildingTypes[buildingType.buildingTypeId],
            data: locationWithMeasurements
                .map(l => {
                    const name = RefdataUtils.locationsFormatter(l.location);
                    return ({
                        x: this.energyLabels.indexOf(l.location.info.energyLabel || l.location.refdata?.energyLabel || EnergyCompassParisProofLinePlugin.unknownLabel),
                        y: this.getValueForScale(l),
                        label: name,
                        location: l.location
                    });
                }),
            buildingType: buildingType,
            tooltip: {
                formatter: this.energyCompassTooltipFormatter(),
                data: locationWithMeasurements.map(l => lodash.round(this.getKwhPerSquareMeter(l), 1))
            }
        };
    }

    hasConsumptionData = (loc: LocationWithMeasurements): boolean => {
        return loc.measurements.flatMap(m => Object.entries(m.measurements)
            .filter(e => this.measurementTypes.includes(e[0] as DataType))).length > 0;
    }

    getKwhPerSquareMeter = (loc: LocationWithMeasurements): number => {
        const area = RefdataUtils.getLocationArea(loc.location);
        return lodash.sum(
            loc.measurements.flatMap(m => Object.entries(m.measurements)
                .filter(e => this.measurementTypes.includes(e[0] as DataType)))
                .map(m => EnergyCompassUtils.getValueWithFactor(m[0] as DataType, m[1].map(a => a.value)))) / area;
    }

    getValueForScale = (loc: LocationWithMeasurements): number => {
        const valuePerSquareMeter = this.getKwhPerSquareMeter(loc);
        const buildingType = this.buildingTypes.find(b => b.buildingTypeId === loc.location.info.buildingType);
        const ticks = this.getScaleTicks(buildingType);
        let valueFound = false;
        return ticks.reduce((a, b, currentIndex) => {
            if (valuePerSquareMeter > b) {
                if (currentIndex === ticks.length - 1) {
                    return currentIndex;
                }
                return b;
            }
            if (valueFound) {
                return a;
            }
            valueFound = true;
            const relativePosition = this.calculateRelativePosition(valuePerSquareMeter, a, b);
            return currentIndex - 1 + relativePosition;
        });
    }

    private calculateRelativePosition = (value, min, max) => (value - min) / (max - min);

    private energyCompassTooltipFormatter(): (ctx: TooltipModel<any>, value: number) => string {
        return (ctx, value) => `${this.chartUtils.getNumberTooltipFormatter()(ctx, value)} kWh/m²`;
    }

    stringToRgba(str: string, alpha = 1): string {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash;
        }
        let rgb = [0, 0, 0];
        for (let i = 0; i < rgb.length; i++) {
            rgb[i] = (hash >> (i * 8)) & 255;
        }
        return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
    }

    togglePlottedBuildingType(buildingType: BuildingType) {
        const isShown: boolean = !!this.settings.shownBuildingTypes[buildingType.buildingTypeId];
        if (isShown) {
            delete this.settings.shownBuildingTypes[buildingType.buildingTypeId];
        } else {
            this.settings.shownBuildingTypes[buildingType.buildingTypeId] = buildingType.info;
        }
        this.scatterChartData.datasets.filter(d => d["buildingType"])
            .forEach(d => {
                d.hidden = !this.settings.shownBuildingTypes[d["buildingType"].buildingTypeId];
            });
        this.emitData();
    }

    get energyLabels(): string[] {
        return EnergyCompassUtils.labels.map(e => e as string).concat(this.settings.showUnknown ? [EnergyCompassParisProofLinePlugin.unknownLabel] : []);
    }

    get visibleBuildingTypes(): string[] {
        return this.settings.shownBuildingTypes ? Object.keys(this.settings.shownBuildingTypes) : [];
    }

    toggleShowUnknown = () => {
        this.settings.showUnknown = !this.settings.showUnknown;
        this.sendQuery("getPortfolioMeasurements").subscribe((r: PortfolioMeasurementsResult) => this.updateChart(r));
    }
}

interface PlottedBuildingType extends BuildingType {
    numberOfLocations?: number;
}

export interface EnergyCompassSettings {
    showUnknown: boolean,
    shownBuildingTypes: lodash.Dictionary<BuildingTypeInfo>;
}

interface LocationWithMeasurements {
    location: Location;
    measurements: MeasurementsResult[];
}